
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@/customValidators'
import { VehiclesActions } from '@/store/modules/vehicles/actions'
import { VehicleInterface } from '@/shared/interfaces/vehicle.interface'
import { email } from '@vuelidate/validators'
import { DriverActions } from '@/store/modules/drivers/actions'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import InputError from '@/shared/components/InputError.vue'
import { WorkShiftActions } from '@/store/modules/work-shifts/actions'
import Multiselect from '@vueform/multiselect'

export default defineComponent({
  components: { InputError, Multiselect },
  setup: () => ({ v$: useVuelidate() }),
  mounted () {
    this.$store.dispatch('getUserRoles')
    this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLES_AVAILABLE]}`, { isFree: 1 })
    // this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLES]}`, { pageNumber: 1, pageSize: 100, unAssigned: true })
    this.$store.dispatch(`darkstores/${[DarkStoresActions.FETCH_DARKSTORES]}`, { pageNumber: 1, pageSize: 9999 })
    // this.$store.dispatch(`workShifts/${[WorkShiftActions.FETCH_WORK_SHIFTS]}`)
  },
  data () {
    return {
      email: '',
      role: null as any,
      vehicle: null as any,
      shift: null as any,
      darkStore: null as any,
      invitationLink: null as any
    }
  },
  validations: function () {
    return {
      email: { required, email },
      role: { required },
      vehicle: {
        required: requiredIf(() => this.checkRequiredDriver())
      },
      shift: {
        required: requiredIf(() => this.checkRequiredDriver())
      },
      darkStore: {
        required: requiredIf(() => this.checkRequiredDriver())
      }
    }
  },
  props: {
    driversOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async copyUrl (mytext: string) {
      try {
        await navigator.clipboard.writeText(mytext)
      } catch ($e) {
        alert('Cannot copy')
      }
    },
    closeModal (): void {
      this.$emit('close')
    },
    async submitForm (event: any): Promise<any> {
      event.preventDefault()
      const payload = {
        user_email: this.email,
        role_id: this.role.id
      }
      if (this.isDriver) {
        Object.assign(payload, { dark_store_id: this.darkStore.id, vehicle_id: this.vehicle.id, shift_id: this.shift.id })
      }
      await this.$store.dispatch(`drivers/${[DriverActions.INVITE_DRIVER]}`, payload).then((link: string) => {
        this.invitationLink = link
        // this.$emit('close')
        this.$emit('refresh')
      }).catch(() => {
        // const toast = useToast()
        // toast.error(err?.info?.message, { timeout: 3000 })
      })
    },
    checkRequiredDriver (): boolean {
      return this.role?.name === 'driver'
    }
  },
  computed: {
    roles (): VehicleInterface[] {
      return this.$store.getters.getRoles
    },
    vehicles (): VehicleInterface[] {
      return this.$store.getters['vehicles/getVehiclesWithShiftId']
    },
    isDriver (): boolean {
      return this.role && this.role.value === 'Driver'
    },
    darkstores (): any[] {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    },
    shifts (): any[] {
      return this.$store.getters['workShifts/getWorkShiftsForSelect']
    }
  },
  watch: {
    roles (val) {
      if (val?.length && this.driversOnly) {
        this.role = val.find((role: any) => role.name === 'driver')
      }
    },
    role (val) {
      this.shift = null
      this.vehicle = null
      this.darkStore = null
      if (val) {
        this.$store.dispatch(`workShifts/${[WorkShiftActions.FETCH_WORK_SHIFTS]}`, { darkstoreId: val.id })
      }
    },
    darkStore (val) {
      this.shift = null
      this.vehicle = null
      if (val) {
        this.$store.dispatch(`workShifts/${[WorkShiftActions.FETCH_WORK_SHIFTS]}`, { darkstoreId: val.id })
      }
    }
    // shift (val) {
    //   this.vehicle = null
    //   if (val) {
    //     this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLES_AVAILABLE]}`, { isAssigned: 0 })
    //   }
    // }
  }
})
